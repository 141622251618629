function CreateBanner(options) {
  /**
   * Close the alert banner and set the session storage item
   *
   * @return {void}
   */
  function __closeBanner(alertBanner) {
    alertBanner.style.display = 'none';
    sessionStorage.setItem('alertActive', 'false');
  }

  /**
   * Open the banner and set session storage items. Scroll the element into view.
   *
   * @return {void}
   */
  function __openBanner(alertBanner, timeStamp) {
    sessionStorage.setItem('timeStamp', timeStamp);
    sessionStorage.setItem('alertActive', 'true');
    alertBanner.style.display = 'block';
  }

  /**
   * Setup the functionality for the banner.
   *
   * @return {void}
   */
  function init() {
    // Get alert banner components
    var alertBanner = document.querySelector(options.banner);
    var alertBannerClose = alertBanner.querySelector(options.bannerClose);

    // Ensure that an alert banner exists and that session storage is
    // available for use
    if (
      alertBannerClose instanceof HTMLElement &&
      alertBanner instanceof HTMLElement &&
      'sessionStorage' in window &&
      window.sessionStorage
    ) {
      var timeStamp = alertBanner.getAttribute('data-updated');

      // Close the alert banner
      if (sessionStorage.getItem('alertActive') === 'false') {
        __closeBanner(alertBanner);
      } else {
        __openBanner(alertBanner, timeStamp);
      }

      // Open the alert if the alert timestamp has been updated
      if (
        sessionStorage.getItem('timeStamp') !== timeStamp ||
        sessionStorage.getItem('alertActive') !== 'false'
      ) {
        alertBannerClose.addEventListener('click', function () {
          __closeBanner(alertBanner);
        });

        __openBanner(alertBanner, timeStamp);
      }
    }
  }

  /**
   * Return a publicly available init function to interface with.
   */
  return Object.freeze({
    init: init,
  });
}

// Setup the new banner with simple selectors.
const COVIDBanner = CreateBanner({
  banner: '.banner',
  bannerClose: '.banner__close',
});

// If the page has loaded, initialise the COVID banner.
window.addEventListener('load', function () {
  COVIDBanner.init();
});
